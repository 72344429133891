/* eslint-disable react/no-danger */
import React from 'react';
import { Link, graphql } from 'gatsby';
import PageLayout from '@global/page-layout';
import ReadLink from '@components/ReadLink/ReadLink';
import PropTypes from 'prop-types';
import kebabCase from 'lodash/kebabCase';
import readingTime from 'reading-time';
import {
  ImageContainer,
  TagContainer,
  Tag,
  PostHeaderRow,
  PostDate,
  ArticleLink,
  ArticleFooterList,
  Body,
} from './post.styled';

export const query = graphql`
  query($slug: String!) {
    contentfulBlogPost(slug: { eq: $slug }) {
      title
      tags
      description {
        description
      }
      publishDate(formatString: "MMMM Do, YYYY")
      author {
        name
      }
      heroImage {
        fluid(maxWidth: 1180, background: "rgb:000000") {
          ...GatsbyContentfulFluid
        }
      }
      body {
        body
        childMarkdownRemark {
          html
        }
      }
    }
  }
`;

const PostTemplate = ({ data: { contentfulBlogPost: post }, pageContext }) => {
  const {
    title,
    tags,
    body,
    description,
    heroImage: image,
    publishDate: date,
    author,
  } = post;
  // console.log('post', post);
  const { previous, next } = pageContext;
  const stats = body ? readingTime(body?.body) : '';
  return (
    <PageLayout title={title} description={description?.description}>
      <div className="bg-white pt-6">
        <div className="mx-auto px-4 max-w-screen-lg sm:px-6 lg:px-8">
          <div className="container px-5 py-24 mx-auto">
            <ReadLink to="/blog" text="Back to all posts" />
            <div className="mt-6 space-y-5 sm:space-y-4 md:max-w-xl lg:max-w-3xl xl:max-w-none">
              <h1>
                <span className="mt-1 block text-4xl tracking-tight font-bold sm:text-5xl xl:text-6xl">
                  <span className="block text-gray-900">{title}</span>
                </span>
              </h1>
              <PostHeaderRow>
                <PostDate>
                  {date} — {stats?.text}
                </PostDate>
                <TagContainer>
                  {tags?.map((tag) => (
                    <Tag key={tag} to={`/tags/${kebabCase(tag)}/`}>
                      {tag}
                    </Tag>
                  ))}
                </TagContainer>
              </PostHeaderRow>
            </div>
            <div>
              <div className="container mx-auto flex flex-col px-5 py-12 justify-center items-center">
                {image ? (
                  <ImageContainer
                    fluid={image?.fluid}
                    alt={title}
                    className="w-full rounded-lg"
                  />
                ) : null}
              </div>
              {/* <Body
                dangerouslySetInnerHTML={{
                  __html: body?.body,
                }}
              /> */}
              <Body
                dangerouslySetInnerHTML={{
                  __html: body.childMarkdownRemark.html,
                }}
              />
              <nav className="my-4">
                <ArticleFooterList>
                  <li>
                    {previous && (
                      <ArticleLink to={`/${previous?.slug}`} rel="prev">
                        ← {previous?.title}
                      </ArticleLink>
                    )}
                  </li>
                  <li>
                    {next && (
                      <ArticleLink to={`/${next?.slug}`} rel="next">
                        {next?.title} →
                      </ArticleLink>
                    )}
                  </li>
                </ArticleFooterList>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

PostTemplate.propTypes = {
  data: PropTypes.object,
  pageContext: PropTypes.object,
};

export default PostTemplate;

// replaced this GatsbyContentfulFluid_tracedSVG as it doesnt work in prod
